const FEATURE_NAME = 'companies'

const companiesQueryKeys = {
  list: () => [FEATURE_NAME, 'list'] as const,
  search: (queryParameters: string) => [
    ...companiesQueryKeys.list(),
    queryParameters,
  ],
  detail: (companyCode: string) =>
    [FEATURE_NAME, 'detail', companyCode] as const,
}

export default companiesQueryKeys
